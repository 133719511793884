import React, {
  ReactElement,
  Component,
  ComponentType,
  FC,
  ReactNode,
  useEffect,
  useState,
  useRef,
} from "react";
import APP_ROUTES from "~/src/Constants/APP_ROUTES";
import { connect, useSelector } from "react-redux";
import { RootState } from "~/src/Configurations/AppStore";
// import { NotificationState } from '~/src/Redux/Notifications/Reducer'
import Lottie from "lottie-web/build/player/lottie_light";
import {
  DsBox,
  DsIconButton,
  DsImage,
  DsRemixIcon,
  dsSpacing,
  DsStack,
  DsTypography,
  DsImageProps,
  DsMenu,
  DsMenuItem,
  DsMenuList,
  DsButton,
  DsGrid,
} from "@am92/react-design-system";

import withRouter, { IWithRouterProps } from "~/src/Lib/withRouter";
import images from "../Constants/images";
import { SUPPORT_ROUTES } from "../Pages/Support/support.routes";
import { handleGAPush } from "../Common/googleAnalytics/googleAnalytics";
import { CategoryHelperObject } from "../Common/googleAnalytics/googleAnalyticsInterface";
import { useLocation, useNavigate } from "react-router-dom";
import PaymentNavRightSectionPage from "./PaymentNavRightSection";
import { CustomerDetail } from "../Redux/Customer/Model";

type NavBackButtonProps = {
  onBackClick: () => void;
};

export const NavBackButton: FC<NavBackButtonProps> = ({
  onBackClick,
}: NavBackButtonProps) => (
  <DsRemixIcon
    sx={{ cursor: "pointer" }}
    onClick={onBackClick}
    className="ri-arrow-left-line"
  />
);

type NavCloseButtonProps = {
  onCloseClick: () => void;
};

export const NavCloseButton: FC<NavCloseButtonProps> = ({
  onCloseClick,
}: NavCloseButtonProps) => (
  <DsRemixIcon onClick={onCloseClick} className="ri-close-line" />
);

const NavRightSection = () => {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);
  const gacall = (type: string) => {
    handleGAPush(
      CategoryHelperObject.categoryPayBills,
      "pay bills | Support+FAQ+Tutorials",
      "Support+FAQ+Tutorials",
      type
    );
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    handleGAPush(
      CategoryHelperObject.categoryPayBills + "Support+FAQ+Tutorials",
      "page load",
      "pop up | Support ,FAQ,Tutorials selection",
      "vpv/pay-bills/ Support+FAQ+Tutorials/Support ,FAQ,Tutorials selection",
      "pay-bills- Support+FAQ+Tutorials-Support ,FAQ,Tutorials selection",
      "event_pay_bills_onload_vpv"
    );
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <DsBox
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          padding: "var(--ds-spacing-bitterCold)",
        }}
      >
        <DsRemixIcon
          className="ri-question-line"
          // onClick={() => {
          //   setOpenMenu(true)
          //   handleGAPush(
          //     CategoryHelperObject.categoryPayBills + 'Support+FAQ+Tutorials',
          //     'page load',
          //     'pop up | Support ,FAQ,Tutorials selection',
          //     'vpv/pay-bills/ Support+FAQ+Tutorials/Support ,FAQ,Tutorials selection',
          //     'pay-bills- Support+FAQ+Tutorials-Support ,FAQ,Tutorials selection',
          //     'event_pay_bills_onload_vpv'
          //   )
          // }}
          onClick={handleClick}
          // size="small"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        />
      </DsBox>

      <DsMenu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <DsMenuItem
          onClick={() => {
            gacall("FAQ");
            navigate(APP_ROUTES.FAQ.pathname);
          }}
        >
          FAQs
        </DsMenuItem>
        <DsMenuItem
          onClick={() => {
            gacall("Support");
            navigate(APP_ROUTES.SUPPORT.pathname);
          }}
        >
          Support
        </DsMenuItem>
        <DsMenuItem
          onClick={() => {
            gacall("Tutorials");
            navigate(SUPPORT_ROUTES.TUTORIAL_PAGE.pathname);
          }}
        >
          Tutorial
        </DsMenuItem>
        {/* </DsMenuList> */}
      </DsMenu>
    </>
  );
};

type NavRightSectionWithSupportProps = {
  children: ReactNode;
};

export const NavRightSectionWithSupport: FC<
  NavRightSectionWithSupportProps
> = ({ children }: NavRightSectionWithSupportProps) => (
  <DsBox display={"flex"} alignItems="center" justifyContent={"space-between"}>
    {children}
    <NavRightSection />
  </DsBox>
);

export const NavRightSectionWithPayment: FC<
  NavRightSectionWithSupportProps
> = ({ children }: NavRightSectionWithSupportProps) => (
  <DsBox display={"flex"} alignItems="center" justifyContent={"space-between"}>
    {children}
    <PaymentNavRightSectionPage />
  </DsBox>
);

type NavigationBarProps = {
  title: string | ReactElement;
  backButton?: ReactElement;
  onBackClick: () => void;
  rightSection?: ReactElement;
  navBg?: string;
};

export const NavigationBar: FC<NavigationBarProps> = ({
  title,
  backButton,
  onBackClick,
  rightSection,
  navBg,
}: NavigationBarProps) => (
  <DsGrid
    position={"sticky"}
    width={"100%"}
    bgcolor={
      navBg ||
      "var(--light-surface-background-sz-colour-surface-background, #FFF);"
    }
    top={0}
    zIndex={9}
  >
    <DsBox>
      <DsBox
        sx={{
          minHeight: "40px",
        }}
        height="56px"
        display="flex"
        justifyContent={"space-between"}
        mt={typeof title == "string" ? 0 : 1}
      >
        <DsBox
          display={"flex"}
          alignItems="center"
          padding="var(--ds-spacing-bitterCold)"
          marginTop="4px"
        >
          {typeof title == "string" &&
          title === "Pay Bills" ? undefined : backButton === undefined ? (
            <NavBackButton onBackClick={onBackClick} />
          ) : (
            backButton
          )}
          {typeof title == "string" ? (
            <>
              <DsTypography
                fontFamily={"Lato"}
                fontSize={"18px"}
                fontWeight={"700"}
                letterSpacing={"0.16px"}
                lineHeight={"24px"}
                fontStyle={"normal"}
                color={
                  "var(--light-typography-primary-sz-colour-typo-primary, #282828)"
                }
                marginLeft="10px"
              >
                {title}
              </DsTypography>
              <DsTypography
                style={{
                  visibility: "hidden",
                }}
                className="password-field-masked"
              >
                x
              </DsTypography>
            </>
          ) : (
            title
          )}
        </DsBox>
        {rightSection === undefined ? <NavRightSection /> : rightSection}
      </DsBox>
    </DsBox>
  </DsGrid>
);

export const withNavigationBar =
  <T extends IWithRouterProps & NavbarInjectedProps>(
    WrappedComponent: ComponentType<T>
  ) =>
  (
    title: string | ReactElement,
    backButton?: ReactElement,
    onBackClick?: () => void,
    rightSection?: ReactElement,
    navBg?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): ComponentType<any> =>
    withRouter(
      class HOC extends Component<
        T,
        {
          title: string | ReactElement;
          navBg?: string;
          rightSection?: ReactElement;
          category: string;
          action: string;
          label: string;
          billerDetails?: any;
        }
      > {
        constructor(props: T) {
          super(props);
          this.state = {
            title: title,
            rightSection: rightSection,
            category: "",
            action: "",
            label: "",
            billerDetails: {},
          };
          this.updateNavTitle = this.updateNavTitle.bind(this);
          this.updateRightSection = this.updateRightSection.bind(this);
          this.updateGADetails = this.updateGADetails.bind(this);
          this.handleBack = this.handleBack.bind(this);
        }

        updateNavTitle(title: string | ReactElement, navBg?: string) {
          this.setState({
            title: title,
            navBg: navBg,
          });
        }

        updateRightSection(rightSection: ReactElement) {
          console.log("CLLED");
          this.setState({
            rightSection,
          });
        }

        updateGADetails(
          category: string,
          action: string,
          label: string,
          args: any
        ) {
          if (args) {
            this.setState({
              category: category,
              action: action,
              label: label,
              billerDetails: args,
            });
          } else {
            this.setState({
              category: category,
              action: action,
              label: label,
            });
          }
        }

        handleBack() {
          if (
            this.state.billerDetails &&
            Object.keys(this.state.billerDetails).length > 0
          ) {
            let billerData = "";
            for (const data in this.state.billerDetails) {
              billerData += `| ${this.state.billerDetails[data]}`;
            }
            handleGAPush(
              `${CategoryHelperObject.categoryPayBills + this.state.category}`,
              this.state.action,
              this.state.label,
              "",
              "",
              "",
              billerData
            );
          } else {
            handleGAPush(
              CategoryHelperObject.categoryPayBills + this.state.category,
              this.state.action,
              this.state.label
            );
          }
          /** FOR HANDLING BACK CLICKS DURING MB HANDSHAKE
           *The previous logic was working based on history, In case of MB handshake we will not have a history,
            we will be directly routed to bill due route
           */

          const customerDetailContext = this.props.customerData;

          let state =
            (this.props.location?.state as {
              billerAccount?: {
                billerCategory?: string;
                billerAccountId?: string;
              };
            }) || undefined;
          if (
            customerDetailContext?.billeraccountid &&
            customerDetailContext.billeraccountid ===
              state?.billerAccount?.billerAccountId
          ) {
            return this.props.navigateTo(APP_ROUTES.HOME.pathname);
          }

          if (this.props.location.pathname.includes("/mobile_prepaid/register"))
            return this.props.navigate(-1);

          if (this.props.location.state?.categoryId === "Fastag") {
            return this.props.navigate(-1);
          } else if (
            this.props.location.pathname.includes("payment-detail") ||
            this.props.location.pathname.includes("register")
          ) {
            console.log("location____", this.props);
            this.props.navigate(-2);
          } else {
            this.props.navigate(-1);
          }
        }

        componentDidMount() {
          window.scrollTo(0, 0);
        }

        render() {
          return (
            <DsBox minHeight={"100vh"} position="relative">
              <NavigationBar
                title={this.state.title}
                backButton={backButton}
                onBackClick={onBackClick ? onBackClick : this.handleBack}
                rightSection={this.state.rightSection}
                navBg={this.state.navBg || navBg}
              />
              <WrappedComponent
                {...this.props}
                updateNavTitle={this.updateNavTitle}
                updateRightSection={this.updateRightSection}
                updateGADetails={this.updateGADetails}
              />
            </DsBox>
          );
        }
      }
    );

export type NavbarInjectedProps = {
  updateNavTitle?: (title: string | ReactElement, navBg?: string) => void;
  updateRightSection?: (rightSection: ReactElement) => void;
  customerData?: CustomerDetail;
  updateGADetails?: (
    category: string,
    action: string,
    label: string,
    args?: any
  ) => void;
};

export const PaymentNavRightSection = (): ReactElement => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <DsBox
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          padding: "16px",
        }}
      >
        <DsRemixIcon
          className="ri-more-2-fill"
          onClick={handleClick}
          // size="small"
          sx={{ margin: "var(--ds-spacing-glacial)" }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        />
      </DsBox>
      <DsMenu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <DsMenuItem>Download</DsMenuItem>
        <DsMenuItem onClick={() => navigate(APP_ROUTES.SUPPORT.pathname)}>
          Support
        </DsMenuItem>
      </DsMenu>
    </>
  );
};

const BillPayLogo: DsImageProps["srcSet"] = [
  {
    src: new URL(`${images.BConnectLogoHorizontal}`, import.meta.url).href,
    alt: "BBPS Image",
    // as: "image/png",
  },
];

export const BillPayLogoNav = (): ReactElement => {
  return (
    <DsBox
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "57.82px",
      }}
    >
      <DsImage
        srcSet={BillPayLogo}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
    </DsBox>
  );
};
