import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "~/src/Redux/Payment/Selectors/CreditCardDetails.selector";

import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import { CreditCardDetailData } from "../Model/CreditCard.model";

export type CreditCardDetailsListState = {
  creditCardData: IRemoteData<CreditCardDetailData[]>;
};

const initialState: CreditCardDetailsListState = {
  creditCardData: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchCreditCardDetailsList(state) {
      state.creditCardData = state.creditCardData.setLoading();
    },
    fetchCreditCardDetailsListFail(
      state,
      action: PayloadAction<ApiResponseError>
    ) {
      state.creditCardData = state.creditCardData.setError(action.payload);
    },
    fetchCreditCardDetailsListSuccess(
      state,
      action: PayloadAction<CreditCardDetailData[]>
    ) {
      state.creditCardData = state.creditCardData.setData(action.payload);
    },
    setCreditCardDataNotLoaded(state) {
      state.creditCardData = new RemoteData();
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const creditCardDetailsSlice = createSlice(sliceOptions);

export default creditCardDetailsSlice.reducer;
export const {
  fetchCreditCardDetailsList,
  fetchCreditCardDetailsListFail,
  fetchCreditCardDetailsListSuccess,
  setCreditCardDataNotLoaded,
} = creditCardDetailsSlice.actions;
